import Vue from 'vue'
import VueRouter from 'vue-router'
import goTo from 'vuetify/lib/services/goto'

Vue.use(VueRouter)

const electron = !(navigator.userAgent.toLowerCase().indexOf(' electron/') === -1)

const routes = [
  {
    path: '*',
    redirect: _ => {
      return { path: '/home' }
    }
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: {
      icon: 'fa-house',
      requiresAuth: false,
      showOnNavbar: false,
      showNavbar: true
    }
  },
  {
    path: '/courses',
    name: 'courses',
    component: () => import('../views/courses/index.vue'),
    meta: {
      icon: 'user-graduate',
      requiresAuth: false,
      showOnNavbar: true,
      showNavbar: true
    }
  },
  {
    path: '/course/:id',
    name: 'view-course',
    component: () => import('../views/courses/viewCourse.vue'),
    meta: {
      icon: 'users-rectangle',
      requiresAuth: false,
      showOnNavbar: false,
      showNavbar: true
    }
  },
  {
    path: '/trip',
    name: 'trips',
    component: () => import('../views/trip/index.vue'),
    meta: {
      icon: 'plane',
      requiresAuth: false,
      showOnNavbar: true,
      showNavbar: true
    }
  },
  {
    path: '/trip/:id',
    name: 'view-trip',
    component: () => import('../views/trip/viewTrip.vue'),
    meta: {
      requiresAuth: false,
      showOnNavbar: false,
      showNavbar: true
    }
  },
  {
    path: '/equipment',
    name: 'equipment',
    component: () => import('../views/Equipment.vue'),
    meta: {
      icon: 'fa-anchor',
      requiresAuth: false,
      showOnNavbar: true,
      showNavbar: true
    }
  },
  {
    path: '/team',
    name: 'team',
    component: () => import('../views/Team.vue'),
    meta: {
      icon: 'fa-people-group',
      requiresAuth: false,
      showOnNavbar: true,
      showNavbar: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
    meta: {
      icon: 'fa-circle-info',
      requiresAuth: false,
      showOnNavbar: true,
      showNavbar: true
    }
  },
  {
    path: '/faq',
    name: 'faq',
    component: () => import('../views/helpful/faq.vue'),
    meta: {
      requiresAuth: false,
      showOnNavbar: false,
      showNavbar: true
    }
  },
  {
    path: '/location',
    name: 'locations',
    component: () => import('../views/location/index.vue'),
    meta: {
      requiresAuth: false,
      showOnNavbar: false,
      showNavbar: true
    }
  },
  {
    path: '/location/:id',
    name: 'view-location',
    component: () => import('../views/location/viewLocation.vue'),
    meta: {
      requiresAuth: false,
      showOnNavbar: false,
      showNavbar: true
    }
  },
  {
    path: '/policy/:scrollTo?',
    name: 'policy',
    component: () => import('../views/helpful/policy.vue'),
    meta: {
      icon: 'fa-message',
      requiresAuth: false,
      showOnNavbar: false,
      showNavbar: true
    }
  },
  require('./checkout').default,
  require('./order').default,
  require('./admin').default,
  require('./authorize').default,
  {
    path: '*',
    redirect: '/'
  }
]

const Router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0

    if (to.hash !== '') {
      scrollTo = to.hash
    }

    return goTo(scrollTo, {
      duration: 0,
      offset: 0,
      easing: 'easeInOutCubic'
    })
  },
  routes
})

Router.beforeEach((to, from, next) => {
  const token = Router.app.$store.getters.doGetToken
  const role = Router.app.$store.getters.doGetRole

  if (to.matched.some(route => route.meta.requiresAuth && !token)) {
    next({ name: 'signin' })
  } else if (to.matched.some(route => route.meta.requiredRoles !== undefined && !route.meta.requiredRoles.includes(role))) {
    next({ name: electron ? 'signin' : 'home' })
  } else {
    next()
  }
})

Router.afterEach((to, from) => {
  document.title = 'Salt & Sea Scuba'

  if (to.matched.some(route => route.meta.requiredRoles !== undefined)) {
    Router.app.$store.dispatch('doVerifyRole').then(role => {
      if (to.matched.some(route => !route.meta.requiredRoles.includes(role))) {
        Router.replace({ name: electron ? 'signin' : 'home' })
      }
    }).catch(_ => {
      Router.app.$store.commit('doResetUser')
      Router.replace({ name: electron ? 'signin' : 'home' })
    })
  }

  new Promise((resolve, reject) => {
    if (Router.app.$store.getters.doGetSession === null) {
      this.setTimeout(() => {
        if (Router.app.$store.getters.doGetSession === null) {
          reject(new Error('Unable to retrieve session'))
        } else {
          resolve(Router.app.$store.getters.doGetSession)
        }
      }, 500)
    } else {
      resolve(Router.app.$store.getters.doGetSession)
    }
  }).then(uuid => {
    Router.app.$services.session.doRoute(uuid, to.fullPath.toString()).then(_ => {
    }).catch(_ => {
    })
  }).catch(_ => {
  })
})

export default Router
